import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();


// full height image
var vpHeight = $(window).height();


$(window).on('resize', function(){
  //var uptop = $('.uptop').height();
  var header = $('#header').height();
	var subt = $(uptop,header).val();
  var vpReHeight = $(window).height();
	var resul = $.subtract(vpReHeight,subt);

	//alert(resul, #header);

	$('.fullheight').css('height', resul);

});
    
function fullheighter() {
	$('.fullheight').css('height', vpHeight);
	};
fullheighter();
	
$(window).on('resize', function(){
	fullheighter();
});


// ====================================
// subscriber
// ====================================

$(function () {
  $('#subForm').submit(function (e) {
    e.preventDefault();
    $.getJSON(
      this.action + "?callback=?",
      $(this).serialize(),
      function (data) {
        if (data.Status === 400) {
          $( ".msgr button" ).html('Error');
          $( ".msgr button" ).css('background-color', '#FC4235');
          $( ".msgr" ).append('<p>' + data.Message + '</p>');
        } else { // 200
          $( ".msgr button" ).html('Success!');
          $( ".msgr button" ).css('background-color', '#14de3a');
          $( ".msgr" ).append('<p>' + data.Message + '</p>');

					document.getElementById("subForm").reset();
        }
    });
  });
});
